import mapboxgl from 'mapbox-gl'
import turf from 'turf-extent';

import svgMaker from '../images/svg/map-marker.svg'





var mbgl = window.mbgl = mapboxgl;

mbgl.accessToken = 'pk.eyJ1IjoiYmNuY28iLCJhIjoiY2wxeHEyNm5xMDBvOTNjcmE4bHRzZHJyeiJ9.d4ATsz8VW2qpcDG-Ic9Sbg';


window.ImageSelector = function(arrayIllustration) {
  // Définir les priorités possibles dans un tableau
  const arrayPriority = ['urlQHD', 'url', 'urlDiaporama', 'urlFiche', 'urlListe'];

  // console.log(arrayIllustration);
  // Parcourir les priorités à partir du début du tableau
  for (let i = 0; i < arrayPriority.length; i++) {
    if (arrayPriority[i] in arrayIllustration) {
      return arrayIllustration[arrayPriority[i]];
    }
  }

  // Si aucune images n'est trouvée
  return null;
}







$(function () {

  let element =   $('#accueil-titre-sinspirer .container ul li > .icon');

  // let headerNoScroll = $('.header-no-scroll');
  // let containerArchive= $('.container-archive');
  //
  // if(headerNoScroll && containerArchive)
  // {
  //   console.log('headerNoScroll && containerArchive')
  //   $('#header').css('margin-top', '0px');
  // }

  console.log('element',element);

})






if(document.getElementById('map')){



  const map = new mapboxgl.Map({
    container: 'map',
// Choose from Mapbox's core styles, or make your own style with Mapbox Studio
    style: 'mapbox://styles/mapbox/streets-v12',
    center: [-103.5917, 40.6699],
    zoom: 3,
  });

  map.scrollZoom.enable();
  map.on('load', () => {

    const width = 30;
    const height = 40;

    const img = new Image(width, height);
// map is your Mapbox GL map object
    img.onload = () => map.addImage('markerPointer', img);
    img.src = svgMaker;
    window.mapTest =map;

    console.log(img);
// Add a new source from our GeoJSON data and
// set the 'cluster' option to true. GL-JS will
// add the point_count property to your source data.
    map.addSource('apidaeData', {
      type: 'geojson',
// Point to GeoJSON data. This example visualizes all M1.0+ apidaeData
// from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      data: {
        'type': 'FeatureCollection',
        'features': [],
      },
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });

    window.updateMapFromGeoJson = function (ArrayData) {
      console.log('geoLocArray',ArrayData);



      let tempArray= [];
      ArrayData.forEach(function(item){
        tempArray.push( {
          'type': 'Feature',
          'properties': item.properties,
          'geometry':item.geoJson,
        })
      })




      let dataSource = {
        'type': 'FeatureCollection',
        'crs': {'type': 'name', 'properties': {'name': 'urn:ogc:def:crs:OGC:1.3:CRS84'}},
        'features': tempArray,
      }
      map.getSource('apidaeData').setData(dataSource);

      if (dataSource.features.length > 1)
      {
        var bbox = turf(dataSource);
        map.fitBounds(bbox,{padding:100});
      }
      else{
        map.flyTo({
          center:dataSource.features[0].geometry.coordinates,
          zoom:12,
        })


      }





    }


    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'apidaeData',
      filter: ['has', 'point_count'],
      paint: {
// Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
// with three steps to implement three types of circles:
//   * Blue, 20px circles when point count is less than 100 -#51bbd6
//   * Yellow, 30px circles when point count is between 100 and 750
//   * Pink, 40px circles when point count is greater than or equal to 750
        /*
        [
          'step',
          ['get', 'point_count'],
          '#1A2740',
          100,
          '#f1f035',
          750,
          '#f28cb1' ],
          */
        'circle-color': '#1A2740',
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,5,
          25,10,
          30,15,
          35,20,
          40,
        ],
        'circle-stroke-width': 10,
        'circle-stroke-color': '#8C8C8C',
        'circle-stroke-opacity': 0.5,
      },
    });

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'apidaeData',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 25,
      },
      paint: {
        'text-color': 'white',
      },
    });

    // map.addLayer({
    //   id: 'unclustered-point',
    //   type: 'circle',
    //   source: 'apidaeData',
    //   filter: ['!', ['has', 'point_count']],
    //   paint: {
    //     'circle-color': '#11b4da',
    //     'circle-radius': 4,
    //     'circle-stroke-width': 1,
    //     'circle-stroke-color': '#fff',
    //   },
    // });
    map.addLayer({
      id: 'unclustered-point',
      type: 'symbol',
      source: 'apidaeData',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'markerPointer',
        'icon-size': 1,
      },
    });


// inspect a cluster on click
    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      const clusterId = features[0].properties.cluster_id;
      map.getSource('apidaeData').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        },
      );
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    map.on('mouseenter', 'unclustered-point', (e) => {
// Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = 'pointer';

// Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

// Populate the popup and set its coordinates
// based on the feature found.
      popup.setLngLat(coordinates).setHTML(description).addTo(map);
    });

    map.on('mouseleave', 'unclustered-point', () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    });

    map.on('click', 'unclustered-point', (e) => {
      window.location.href = e.features[0].properties.id;
    });

// // When a click event occurs on a feature in
// // the unclustered-point layer, open a popup at
// // the location of the feature, with
// // description HTML from its properties.
//   map.on('click', 'unclustered-point', (e) => {
//     const coordinates = e.features[0].geometry.coordinates.slice();
//     const mag = e.features[0].properties.mag;
//     const tsunami =
//       e.features[0].properties.tsunami === 1 ? 'yes' : 'no';
//
// // Ensure that if the map is zoomed out such that
// // multiple copies of the feature are visible, the
// // popup appears over the copy being pointed to.
//     while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
//       coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
//     }
//
//     new mapboxgl.Popup()
//       .setLngLat(coordinates)
//       .setHTML(
//         `magnitude: ${mag}<br>Was there a tsunami?: ${tsunami}`
//       )
//       .addTo(map);
//   });

    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = '';
    });
  });
}

